import React from "react";
import config from "../../../../config/config";
import { Link } from "react-router-dom"
import { Translation } from 'react-i18next';

import { ReactComponent as PinIcon } from '../../../../images/pin.svg';
import { ReactComponent as ShopIcon } from '../../../../images/shop.svg';
import { ReactComponent as SearchIcon } from '../../../../images/search.svg';
import Logo from "../../../../images/coop-puglia.png";

import HeaderItem from "../HeaderItems/HeaderItem/HeaderItem";
import HeaderLink from "../HeaderItems/HeaderLink/HeaderLink";
import HeaderCategoriesItem from "../HeaderItems/HeaderCategoriesItem/HeaderCategoriesItem";

import "./new-header.styles.scss";
import dayOfWeek from "../../../../utils/dateUtils";


class NewHeader extends React.Component {

    updateCart = () => {
        if (this.props.user && config.SHOW_CART_UPDATE) {
            return (
                <Link className="pin-link" to="/cart/update">
                    <nobr>
                        <span>{this.props.cart && this.props.cart.storeDescription ?
                            this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                    </nobr>
                </Link>
            );
        } else {
            return (
                <nobr>
                    <span>{this.props.cart && this.props.cart.storeDescription ?
                        this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                </nobr>
            );
        }
    }

    render() {
        const { user, cart, searchInput, onChange, closeAll, availability } = this.props;
        const availableDay = availability && dayOfWeek(availability.weekDay)
        return (
            <Translation>
                {t =>
                    <div className="header">
                        <div className="header-wrapper">
                            <div className="container-fluid">
                                <div className="row header-top">
                                    <div className="col">
                                        <PinIcon className="pin" />
                                        {this.updateCart()}
                                    </div>
                                    <div className="col order">
                                        {/* {user && order &&
                                            <nobr><div>
                                                il tuo ordine n°
                                                <Link className="order-link" to="/orders">
                                                    <span> {order && order.code} </span>
                                                </Link>
                                                è <span className="status">{order && t(`order.status.${order.status}`)}</span>
                                            </div></nobr>
                                        } */}
                                        {cart && availability &&
                                            <div>
                                                Prima disponibilità:
                                                <span className="mr-2"> {t(`daysOfWeek.${availableDay.name}`)}</span>
                                                <span>{availableDay.dayNumber} {t(`months.${availableDay.month}`)}</span>
                                                <span> ({availability.validFrom} - {availability.validTo})</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="col text-right">
                                        <Link className="shop-link" to="/stores">
                                            <ShopIcon className="shop" />
                                            <span>{t("stores")}</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row header-bottom">
                                    <div className="col-4">
                                        <div className="d-flex pr-md-4 align-items-center navigation-logo">
                                            <Link to="/" onClick={closeAll}>
                                                <img src={Logo} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-4 search">
                                        <form onSubmit={e => {
                                            e.preventDefault();
                                            this.props.history.push({
                                                pathname: "/search",
                                                state: { searchInput: searchInput }
                                            })
                                            closeAll();
                                        }}>
                                            <input type="search" onChange={onChange} value={searchInput}
                                                placeholder="Ricerca prodotto o categoria"></input>
                                            <button type="submit" className="outline">
                                                <SearchIcon />
                                            </button>
                                        </form>

                                    </div>
                                    <div className="col-4">
                                        <div className="d-flex justify-content-end">
                                            <HeaderCategoriesItem
                                                label="products"
                                                alwaysActived={false}
                                                onClick={this.props.categoriesModal}
                                                actived={this.props.showCategories}
                                            />
                                            <HeaderItem
                                                icon="user"
                                                label={user ? user.firstName : "access"}
                                                alwaysActived={false}
                                                onClick={this.props.loginModal}
                                                actived={this.props.showLogin}
                                            />
                                            {user &&
                                                <HeaderLink
                                                    icon="shopping-cart"
                                                    path="/cart"
                                                    footer={this.props.renderTotal(cart)}
                                                    items={cart ? cart.itemsCount : "0"}
                                                    closeAll={this.props.closeAll}
                                                />
                                            }
                                            {!user &&
                                                <HeaderItem
                                                    icon="shopping-cart"
                                                    label="cart"
                                                    alwaysActived={false}
                                                    onClick={this.props.loginModal}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default NewHeader;
