import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translation } from 'react-i18next';

const headerItem = (props) => {
    let cssClass;
    if (props.alwaysActived) {
        cssClass = "navigation-box navigation-box__active"
    } else {
        cssClass = props.actived ? "navigation-box navigation-box__active" : "navigation-box";
    }
    return (
        <Translation>
            {t =>
                <div id={props.icon} className="navigation-item" onClick={props.onClick}>
                    <div className={cssClass}>
                        <div className="navigation-box__wrapper text-center">
                            <div className="navigation-box__spacer"/>
                            <div className="navigation-box__icon">
                                <FontAwesomeIcon icon={props.icon} size="2x" />
                            </div>
                            <span className="d-none d-lg-block">{t(`${props.label ? props.label : "Profilo"}`)}</span>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    );
}

export default headerItem;