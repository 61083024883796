import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';

export const authenticationService = { login, logout, signup, isLogged, updateUser, coopSignup, deleteUser };

function login(data) {
    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(data.username + ":" + data.password));
    return (
        fetch(`${config.baseUrl}/cli/profiles/login`, {
            method: 'GET',
            headers: headers,
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem('user', JSON.stringify(responseData.data));
                return responseData;
            })
    );
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user")
    return (
        fetch(`${config.baseUrl}/cli/profiles/logout`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function signup(data) {
    console.log(data);
    return (
        fetch(`${config.baseUrl}/cli/profiles/signup`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({
                username: data.username,
                password: data.password,
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    birthDate: data.birthDate,
                    mobilePhone: data.mobilePhone,
                    email: data.username,
                    cards: [{
                        code: data.profileCard,
                        principal: true,
                        enabled: true
                    }],
                    accept1: data.accept1,
                    accept2: data.accept2,
                    accept3: data.accept3,
                    accept4: data.accept4,
                },
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function isLogged() {
    return (
        fetch(`${config.baseUrl}/cli/profiles/login`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function updateUser(data) {
    console.log(data);
    return (
        fetch(`${config.baseUrl}/cli/profiles`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                mobilePhone: data.mobilePhone,
                ssnCode: data.ssnCode,
                // birthDate: data.birthDate,
                cards: data.profileCard !== "" ?
                    [{
                        code: data.profileCard,
                        principal: true,
                        enabled: true
                    }]
                    : null,
                // email: data.email,
                // gender: data.gender,
                accept1: data.accept1,
                accept2: data.accept2,
                accept3: data.accept3,
                accept4: data.accept4,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function coopSignup(data) {
    return (
        fetch(`${config.baseUrl}/cli/profiles/signup`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({
                username: data.username,
                password: data.password,
                profile: {
                    accept1: data.accept1,
                    accept2: data.accept2,
                    accept3: data.accept3,
                    accept4: data.accept4,
                },
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function deleteUser() {
    return (
        fetch(`${config.baseUrl}/cli/profiles/deleteRequest`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                return responseData;
            })
    );
}