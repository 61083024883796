import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const counter = (props) => {
    const value = props.um ? `${props.value} ${props.um.toLowerCase()}` : `${props.value}`;
    return (
        <div style={{ ...props.styles }} className="product-detail-counter">
            <div>
                <button className="outline" onClick={props.decrement}>
                    <FontAwesomeIcon icon="minus" size="1x" />
                </button>
            </div>
            <div className="justify-content-center">
                <span style={{ textTransform: "unset" }} className="text-center primary-text">{value}</span>
            </div>
            <div>
                <button className="outline" onClick={props.increment}>
                    <FontAwesomeIcon icon="plus" size="1x" />
                </button>
            </div>
        </div>
    );
}

export default counter;