import React from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import passwordChange from "./Service"
import swal from "../../../utils/swal"
import { Container, Row, Col } from 'react-bootstrap';
import ValidateInput from '../../../components/UI/Input/ValidateInput';
import { history } from "../../../App";

function PasswordChange(props) {

    const { register, handleSubmit, watch, errors } = useForm({
        mode: 'onBlur',
    });

    const changePassword = data => {
        passwordChange(data.oldPassword, data.newPassword)
            .then(response => {
                swal.success("Password aggiornata correttamente");
                history.push("/");
            }).catch((error) => {
                swal.error(error.message);
            })
    }

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(changePassword)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <Container className="signup-wrapper">
                        <div style={{ maxWidth: "750px" }} className="signup-form">
                            <p className="signup-form-title">{t("passwordChange.title")}</p>
                            <div>
                                <Row className="signup-row">
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="oldPassword"
                                            label="oldPassword"
                                            type="password"
                                            watch={watch("oldPassword")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.oldPassword && errors.oldPassword.message}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="signup-row">
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="newPassword"
                                            label="newPassword"
                                            type="password"
                                            watch={watch("newPassword")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.newPassword && errors.newPassword.message}
                                        </div>
                                        <small id="newPasswordHelp" className="form-text text-muted">
                                            La password deve essere lunga almeno 8 caratteri e contenere
                                            almeno tre tra: lettere minuscole, maiuscole, numeri e simboli
                                        </small>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="passwordConfirm"
                                            label="passwordConfirm"
                                            type="password"
                                            watch={watch("passwordConfirm")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                validate: {
                                                    passwordMatch: value => value === watch('newPassword')
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.passwordConfirm && errors.passwordConfirm.message}
                                            {errors.passwordConfirm && errors.passwordConfirm.type === "passwordMatch" && (
                                                <span>Le password non coincidono</span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <button type="submit" className="mt-3 smartlist-header-button outline">
                                    {t("passwordChange.send")}
                                </button>
                            </div>
                        </div>
                    </Container>
                </form>
            }
        </Translation>
    );
}

export default PasswordChange;



