import React from "react"
import Container from "react-bootstrap/Container"
// import SignupForm from "./SignupForm/SignupForm"
import CoopSignupForm from "./SignupForm/CoopSignupForm"

export default class Signup extends React.Component {
    
    render() {
        return (
            <Container className="signup-wrapper">
                {/* <SignupForm /> */}
                <CoopSignupForm />
            </Container>
        );
    }
}