import React from "react";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
// import HomeCategories from "./HomeCategories/HomeCategories";

export default class Home extends React.Component {
    render() {
        return (
            <div className="mobile-view-search">
                <HomeCarousel />
                {/* <HomeCategories /> */}
                <FeaturedProducts />
            </div>
        );
    }
}
