import React from "react"
import disponibility from "./Service"
import dayOfWeek from "../../../../utils/dateUtils"
import Slider from "../../../../components/UI/Slider/Slider";
import { Translation } from 'react-i18next';

export default class Days extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            days: [],
            loaded: false
        };
    }

    componentDidMount() {
        disponibility(this.props.cartId)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        days: response.data,
                        loaded: true
                    })
                    this.props.selectDay(response.data[0]);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cartId !== this.props.cartId) {
            disponibility(this.props.cartId)
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            days: response.data,
                            loaded: true
                        })
                        this.props.selectDay(response.data[0]);
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    render() {
        const { days, loaded } = this.state;
        const data = dayOfWeek(days)
        if (loaded){
            if (days && days.length > 0) {
                return (
                    <Translation>
                        {t =>
                            <Slider>
                                {days && data.map((day) => (
                                    <button key={day.day} id={day.day} className="disponibility-button outline title" onClick={() => this.props.selectDay(day.day)}>
                                        <div className="text-center">
                                            <span>{t(`daysOfWeek.${day.name}`)}</span>
                                        </div>
                                        <div className="text-center" style={{ fontSize: '30px' }}>
                                            <span>{day.dayNumber}</span>
                                        </div>
                                        <div className="text-center">
                                            <span>{t(`months.${day.month}`)}</span>
                                        </div>
                                    </button>
                                ))}
                            </Slider>
                        }
                    </Translation>
                );
            } else {
                return (
                    <span className="text-center primary-text">
                        A CAUSA DELL'ELEVATO NUMERO DI RICHIESTE, NON CI SONO FASCE DI CONSEGNA DISPONIBILI AL MOMENTO. <br/>
                        RIPROVA PIU' TARDI
                    </span>
                );
            }
        } else {
            return (
                <span className="text-center primary-text">
                    ATTENDERE...
                </span>
            );
        }
    }
}