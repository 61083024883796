import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";

export default function boardingForm() {
    return (
        fetch(`${config.baseUrl}/cli/pay/bnl/boarding/web`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
        .then(handleResponse)
    );
}