import React, { useState } from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Container from "react-bootstrap/Container"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import Accepts from "../../../components/Accepts/Accepts";
import "../profile.styles.scss"

function Profile(props) {
    const [showModal, setShowModal] = useState(false)

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.updateUser(data);
    }

    // console.log(watch())
    // console.log(watch('errors'))

    const handleCard = (card) => {
        if (isNaN(parseInt(card.charAt(card.length - 1)))) {
            setValue("profileCard", card.slice(0, card.length - 1))
        }
        if (card.length > 13) {
            setValue("profileCard", card.slice(0, 13))
        }
    }

    return (
        <Translation>
            {t =>

                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <Modal show={showModal} centered={true} onHide={() => setShowModal(false)} dialogClassName="profile-delete-modal">
                        <div style={{ padding: '30px' }}>
                            <div style={{ marginBottom: '1rem' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '25px' }}>Sei sicuro di voler eliminare il profilo?</span><br /><br />
                                Il processo di eliminazione del profilo creato sul sito coop-puglia.smartbip.it non è immediato e prevede l'invio della richiesta a COOP che la prenderà in carico e ne darà riscontro entro 48 ore previa verifica di tutte le condizioni necessarie.<br /><br />
                                Non è ad esempio possibile eliminare automaticamente un profilo che ha una spesa in corso.<br /><br />
                                In ogni caso puoi sempre recarti in un Punto Vendita per gestire la tua richiesta.<br /><br />
                                <span style={{ fontWeight: 'bold', fontSize: '25px' }}>Confermi di voler procedere con la richiesta di eliminazione?</span><br />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                <button
                                    className="custom-button"
                                    onClick={(e) => { e.preventDefault(); setShowModal(false) }}
                                >
                                    <span>{t("cancel")}</span>
                                </button>
                                <button
                                    className="custom-button"
                                    onClick={(e) => { e.preventDefault(); props.deleteUser(); setShowModal(false) }}
                                >
                                    <span>{t("confirm")}</span>
                                </button>
                            </div>
                        </div>
                    </Modal>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                    <Container className="signup-wrapper">
                        <div className="signup-form">
                            <p className="signup-form-title">{t('signup.title')}</p>
                            <div>
                                <Row className="signup-row">
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="firstName"
                                            label="firstName"
                                            type="text"
                                            value={props.user && props.user.firstName}
                                            watch={watch("firstName")}
                                            register={register({
                                                required: "Campo obbligatorio"
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.firstName && errors.firstName.message}
                                        </div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="lastName"
                                            label="lastName"
                                            type="text"
                                            value={props.user && props.user.lastName}
                                            watch={watch("lastName")}
                                            register={register({
                                                required: "Campo obbligatorio"
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.lastName && errors.lastName.message}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="signup-row">
                                    {/* <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="birthDate"
                                            label="birthDate"
                                            type="date"
                                            value={props.user && props.user.birthDate &&
                                                format(parseISO(props.user.birthDate), "yyyy-MM-dd")}
                                            watch={watch("birthDate")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^\d{4}-\d{2}-\d{2}$/i,
                                                    message: "Data non valida"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.birthDate && errors.birthDate.message}
                                        </div>
                                    </Col> */}
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="ssnCode"
                                            label="ssnCode"
                                            type="text"
                                            value={props.user && props.user.ssnCode}
                                            watch={watch("ssnCode")}
                                            maxLength={16}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/,
                                                    message: "Formato errato"
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: "Codice fiscale non valido"
                                                },
                                                minLength: {
                                                    value: 16,
                                                    message: "Codice fiscale non valido"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.ssnCode && errors.ssnCode.message}
                                        </div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="mobilePhone"
                                            label="mobilePhone"
                                            type="text"
                                            value={props.user && props.user.mobilePhone}
                                            watch={watch("mobilePhone")}
                                            maxLength={10}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^\d{10}$/i,
                                                    message: "Numero di cellulare non valido"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.mobilePhone && errors.mobilePhone.message}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <p className="signup-form-title mt-3">{t('signup.card')}</p>

                            <div>
                                <Row className="signup-row">
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="profileCard"
                                            label="profileCard"
                                            type="text"
                                            value={props.user && props.user.cards ? props.user.cards[0] && props.user.cards[0].code : '0248'}
                                            watch={watch("profileCard")}
                                            register={register({
                                                pattern: {
                                                    value: /^0248[0-9]{9}$/,
                                                    message: "Carta non valida"
                                                },
                                                maxLength: {
                                                    value: 13,
                                                    message: "Carta non valida"
                                                },
                                                minLength: {
                                                    value: 13,
                                                    message: "La carta fedeltà deve avere 13 caratteri"
                                                }
                                            })}
                                            maxLength={13}
                                            minLength={13}
                                            onChange={(e) => handleCard(e.target.value)}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.profileCard && errors.profileCard.message}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <p className="signup-form-title mt-3">{t('signup.privacy')}</p>

                            <Accepts errors={errors} register={register} user={props.user} disabled={true} />

                            <div className="mt-3 mb-3">
                                <button className="custom-button outline" type="submit">
                                    {t("save")}
                                </button>
                            </div>
                            <div className="mt-3 mb-3">
                                <button className="custom-button" onClick={(e) => { e.preventDefault(); setShowModal(true) }}>
                                    {t("deleteProfile")}
                                </button>
                            </div>
                        </div>
                    </Container>

                </form>
            }
        </Translation>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (data) => dispatch(actionCreators.updateUser(data)),
        deleteUser: (data) => dispatch(actionCreators.deleteUser()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);