import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import config from "../../../config/config"
import { ReactComponent as SlistIcon } from "../../../images/slist.svg"
import { history } from "../../../App";
import { format, parseISO } from "date-fns";

import "./card-product.styles.scss";

class CardProduct extends React.Component {
    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className="text-strike strike">
                        {`${item.price.toFixed(2)} \u20AC`}
                    </span>
                </nobr>
            );
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                return (
                    <div>
                        <div className="promo-title">{item.promotions[0].description}</div>
                        <div className="promo-valid">
                            Dal {format(parseISO(item.promotions[0].validFrom), "dd/MM")} al {format(parseISO(item.promotions[0].validTo), "dd/MM")}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{ lineHeight: "42px" }}>
                        <div>&nbsp;</div>
                    </div>
                );
            }
        }
    }


    isAlreadyBooked = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        if (this.props.cart.items) {
            const items = this.props.cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
            return;
        }

        if (isAlreadyBooked) {
            const addItem = { ...itemToAdd, available: true }
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: addItem, isDetail: true }
            })
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
        }
    }

    goToDetails = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        const { cart, from, category, mainCategory, categoryTitle, subCategory, activeKey } = this.props;
        if (cart && cart.items) {
            const items = cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
            return;
        }

        if (isAlreadyBooked) {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: itemToAdd, isDetail: true, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
        }
    }

    render() {
        const { item, user, currentSlist, cart, setProductToAdd } = this.props;
        return (
            <div className="card-product" style={{ ...this.props.style }}>
                <div className={"card-product-container"}>
                    <div className={"card-product-image"}>
                        <div className="link" onClick={() => this.goToDetails(item)}>
                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                onError={(e) => { e.target.src = Placeholder }}
                            />
                        </div>
                    </div>
                    {/* <div className={"card-product-promo"}>
                        {this.renderPromo(item)}
                    </div> */}
                </div>
                <div className="card-product-details">
                    <div className="link" onClick={() => this.goToDetails(item)}>
                        <span>{item.description}</span>
                    </div>
                </div>
                {/* <div className="card-product-um">
                    {item.pricePerUm && item.umPerUm &&
                        `${item.pricePerUm.toFixed(2)} \u20AC / ${item.umPerUm} `
                    }
                </div> */}
                <div className="card-product-promo-puglia">
                    {this.renderPromo(item)}
                </div>
                <div className="card-product-prices">
                    {this.renderPrices(item)}
                    <span></span>
                    <nobr><span className="netTotal">{`${item.netPrice.toFixed(2)} \u20AC / ${item.purchaseUm.toLowerCase()}`}</span></nobr>
                </div>

                <div className="card-product-buttons row">
                    {config.ENABLED_SLIST &&
                        <div className="col-4 col-sm-2">
                            {user &&
                                <button className="slist outline"
                                    onClick={() => currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)}>
                                    <SlistIcon />
                                </button>
                            }
                            {!user &&
                                <button className="slist outline"
                                    onClick={() => { document.getElementById('user').click(); }}>
                                    <SlistIcon />
                                </button>
                            }
                        </div>
                    }
                    <div className={`${config.ENABLED_SLIST ? "col-4 col-sm-10" : "col-12"}`}>
                        {user && cart &&
                            <button style={buttonStyle} className="cart outline"
                                // onClick={() => cart && store(cart.id, item.barcode, 1, item.um)}>
                                onClick={() => cart && this.isAlreadyBooked(item)}>
                                <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                <span className="d-none d-sm-inline">ACQUISTA</span>
                            </button>
                        }
                        {user && !cart &&
                            <button style={buttonStyle} className="cart outline"
                                onClick={() => {
                                    setProductToAdd(item, 1);
                                    document.getElementById('shopping-cart').click();
                                }}>
                                <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                <span className="d-none d-sm-inline">ACQUISTA</span>
                            </button>
                        }
                        {!user &&
                            <button style={buttonStyle} className="cart outline"
                                onClick={() => {
                                    setProductToAdd(item, 1);
                                    document.getElementById('user').click();
                                }}>
                                <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                <span className="d-none d-sm-inline">ACQUISTA</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const buttonStyle = {
    width: config.ENABLED_SLIST ? "" : "100%",
    marginLeft: config.ENABLED_SLIST ? "" : "unset",
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardProduct);