import React from "react";
import CookieConsent from "react-cookie-consent";
import FacebookIcon from "../../../images/facebook-square-brands.svg";
import PlayStore from "../../../images/PlayStore.png";
import AppStore from "../../../images/AppStore.png";
import InstagramIcon from "../../../images/instagram-square-brands.svg";
// import Logo from "../../../images/logo-fresco-bianco.png";
import { isMobile } from "react-device-detect";

import "./footer.styles.scss";

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <CookieConsent
                    location="bottom"
                    buttonText="OK"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}>
                    Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito.
                    Continuando a navigare nel sito si accetta la nostra politica cookie.
                </CookieConsent>
                <div className="footer-content">
                    <div className="row container-fluid">
                        <div className="col-lg-2 col-md-6 col-12 text-center text-md-left mobile-col">
                            <div className="footer-link">
                                <a href="https://coop-puglia.smartbip.it/resources/faq.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    Faq
                                </a>
                            </div>
                            <div className="footer-link">
                                <a href="https://coop-puglia.smartbip.it/resources/tec.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    Termini e condizioni
                                </a>
                            </div>
                            <div className="footer-link">
                                <a href="http://www.coopmasterpuglia.it/privacy-policy-2/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 offset-lg-1 col-md-6 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col">
                            <div className="footer-text">
                                Ordini telefonici
                            </div>
                            <div className="footer-text">
                                <span className="bold">
                                    +39 3456030207
                                </span>
                            </div>
                            <div className="footer-text">
                                Assistenza e Ordini Spesa a Domicilio
                            </div>
                            <div className="footer-text">
                                <span className="bold">
                                    Lun-Ven 8:30-13:30 | 15:30-17:30 <br />
                                    Sab 8:30-13:30
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 text-center text-md-left mobile-col">
                            <div className="footer-text">
                                Vuoi rimanere aggiornato sulle nostre attività?
                            </div>
                            <div className="footer-text last">
                                Visita il nostro&nbsp;
                                <a href="https://www.coopmasterpuglia.it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    <span className="bold">Sito istituzionale</span>
                                </a>
                            </div>
                            {/* <div className="footer-text footer-social d-flex">
                                <span>Seguici su:</span>
                                <a href="https://www.facebook.com/coopmasterpugliabasilicata/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="footer-social-icon"
                                >
                                    <img src={FacebookIcon} alt="facebook" />
                                </a>
                                <a href="https://www.instagram.com/coopmasterpugliaebasilicata"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="footer-social-icon"
                                >
                                    <img src={InstagramIcon} alt="facebook" />
                                </a>
                            </div> */}
                        </div>
                        {isMobile &&
                            <div className="col-lg-2 offset-lg-1 col-md-6 col-12 text-center text-md-left">
                                <div className="footer-text">
                                    Scarica la nostra APP:
                                </div>
                                <div className="footer-apps">
                                    <div>
                                        <a href="https://apps.apple.com/it/app/coop-master-puglia/id1179715827"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <button className="outline">
                                                <img src={AppStore} alt="" />
                                                <span className="bold">App store</span>
                                            </button>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="https://play.google.com/store/apps/details?id=com.retapps.coopmasterpuglia.android.app&hl=it"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <button className="outline">
                                                <img src={PlayStore} alt="" />
                                                <span className="bold">Play store</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="footer-bottom row no-gutters">
                    <div className="col-12 col-sm-8 text-center text-sm-left" >
                        <span>
                            {new Date().getFullYear()}  © Tatò Paride spa
                        </span>
                    </div>
                    <div className="col-12 col-sm-4 text-center text-sm-right">
                        <span>
                            Powered by <span className="bold">SmartBip</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}